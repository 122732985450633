import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`D`}<em parentName="p">{`eload Week`}</em></p>
    <p>{`DB Front Squats 3×12`}</p>
    <p>{`Stability Ball Hamstring Curls 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 each:`}</p>
    <p>{`Calorie Ski Erg`}</p>
    <p>{`DB Hang Power Cleans (35/25’s)(RX+ 50/35’s)`}</p>
    <p>{`Alternating DB Reverse Lunges `}<em parentName="p">{`(15/10/5 per leg)`}</em></p>
    <p>{`K2E’s`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      